<template>
  <div class="py-2">
    <div style="max-width: 320px; min-width: 320px">
      <v-col
        cols="12"
        v-if="item.messages && item.messages.length > 0"
        class="msg-block"
        ref="msgBlock"
      >
        <div
          :key="`msg_${msg.id}`"
          v-for="msg in item.messages"
          :class="`chat-msg ${msgType(msg)}`"
        >
          <div class="chat-name">
            {{ msg.identity_name }} {{ formatDateTime(msg.created_at) }}
          </div>
          <div class="chat-content">
            <img
              v-if="msg.file"
              :src="urlFile(msg)"
              style="max-width: 100%"
              @click="showImages(urlFile(msg))"
            />
            <span v-if="msg.content">{{ msg.content }}</span>
          </div>
        </div>
      </v-col>
      <div v-if="checkRoleInput">
        <v-text-field
          v-model="content"
          label="Nhập nội dung..."
          class="c-input-small"
          dense
          outlined
          clearable
          hide-details
          single-line
          @keyup.enter="sendNewMessage"
        >
          <template v-slot:append>
            <img
              class="cursor-pointer"
              width="24"
              height="24"
              src="@/assets/internal_requests/add-image.png"
              @click="$refs.inputUploadFile.click()"
            />
          </template>
        </v-text-field>
        <input
          type="file"
          ref="inputUploadFile"
          accept="image/*"
          @change="onInputFileChange"
          class="d-none"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { api as viewerApi } from "v-viewer";
import { compress } from "image-conversion";
import { formatDateTime, generateRandomString } from "@/libs/helpers";
import moment from "moment";

export default {
  name: "Messages",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    content: null,
    file: null,
    isLoading: false,
    viewerOptions: {
      movable: false,
      button: true,
      navbar: true,
      title: false,
      toolbar: true,
      tooltip: false,
      zoomable: false,
      rotatable: false,
      scalable: false,
      transition: true,
      fullscreen: false,
      keyboard: true,
    },
  }),
  computed: {
    itemMessages() {
      return this.item.messages.filter((i) => !!i.content);
    },
    urlFile() {
      return (msg) => process.env.VUE_APP_FILE_CDN_URL + "/" + msg.file;
    },
    imageUrls() {
      const urls = [];
      this.item.messages.forEach((i) => {
        if (i.file) {
          urls.push(this.urlFile(i));
        }
      });
      return urls;
    },
    checkRoleInput() {
      return (
        this.item.status !== "processed" &&
        this.item.request_member_ids.includes(`Ω${window.me.identity_id}Ω`)
      );
    },
    msgType() {
      if (!window.me || !window.me.identity_id) {
        return "chat-type-1";
      }
      const identityId = window.me.identity_id;
      return (msg) =>
        identityId === msg.identity_id ? "chat-type-2" : "chat-type-1";
    },
  },
  methods: {
    formatDateTime,
    generateRandomString,
    onInputFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      this.uploadFile(files[0]);
    },

    async uploadFile(file) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      const resBlob = await compress(file, {
        quality: 0.85,
        type: "image/jpeg",
      });
      const newFile = new File(
        [resBlob],
        `${moment().unix()}_${this.generateRandomString(10)}.jpg`,
        { type: "image/jpeg" }
      );
      let fd = new FormData();
      fd.append("file", newFile);

      httpClient
        .post(`/common/v1/upload-image`, fd)
        .then((res) => {
          this.sendNewFile({ id: this.item.id, file: res.data });
          this.$refs.inputUploadFile.value = null;
          this.isLoading = false;
        })
        .catch((err) => {
          console.log("uploadFile err", err);
          this.$refs.inputUploadFile.value = null;
          this.isLoading = false;
        });
    },
    async sendNewFile(msg) {
      this.$emit("sendNewMessage", { ...msg });
    },
    async sendNewMessage() {
      this.$emit("sendNewMessage", { ...this.item, content: this.content });
      this.content = null;
    },
    showImages(url) {
      const index = this.imageUrls.findIndex((u) => u === url);
      viewerApi({
        options: {
          toolbar: true,
          initialViewIndex: index,
        },
        images: this.imageUrls,
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.msgBlock) {
        this.$refs.msgBlock.scrollTo(0, this.$refs.msgBlock.scrollHeight + 200);
      }
    });
  },
};
</script>

<style scoped lang="scss">
.msg-block {
  width: 100%;
  min-width: 300px;
  padding-top: 3px;
  padding-bottom: 3px;
  height: 120px;
  overflow-y: auto;
  border: solid 1px black;
  border-radius: 3px;
  background-color: white;
}

.chat-msg {
  margin-bottom: 10px;
}

.chat-name {
  background-color: transparent;
  font-style: italic;
  font-size: 2.5mm;
  color: grey;
}

.chat-type-1 {
  .chat-content {
    padding: 5px;
    width: auto;
    max-width: 80%;
    //float: left;
    background-color: lightgrey;
    border: 1px solid transparent;
    border-radius: 10px;
    text-align: left;
    text-wrap: wrap;
  }
}

.chat-type-2 {
  //float: right;
  text-align: right;

  .chat-content {
    padding: 5px;
    border: 1px solid transparent;
    border-radius: 10px;
    background-color: dodgerblue !important;
    color: white !important;
    width: 80%;
    margin-left: 20%;
    text-wrap: wrap;
    //float: right;
  }
}
</style>
